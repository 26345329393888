export default {
  components: {     
   },

  data() {
    return {
      flexgrid: {},
      keyTabelaWijmo: 0,
      dados: [],
      dadosD: [],
      abas: [],
      dialog: false,
      abaEscolhida: "",
      colunas: [
        {
          text: 'Loja',
          align: 'start',
          value: 'ds_loja',
        },
        {
          text: 'GUID',
          align: 'center',
          value: 'id_guid',
        }, 
        {
          text: 'Descrição do lote',
          align: 'start',
          value: 'ds_desc',
        },   
        {
          text: 'Qt. prod',
          align: 'center',
          value: 'qt_prod',
          sortable: false, 
        },           
        {
          text: 'Usuário',
          align: 'start',
          value: 'ds_usuario',
        },
        {
          text: 'Dt. Inclusão',
          align: 'center',
          value: 'dt_incsis',
        },
        {
          text: 'Situação',
          align: 'start',
          value: 'ds_situac',
        },
        {
          text: 'Usuário Ação',
          align: 'start',
          value: 'ds_usuario_liberador',
        },
        {
          text: 'Dt. Ação',
          align: 'start',
          value: 'dt_oper_acao',
        },        
        { 
          text: 'Ações', 
          align: 'center',
          value: 'actions', 
          sortable: false, 
        },
      ],
      colunasD: [
        {
          text: 'SKU',
          align: 'start',
          value: 'sku',
        },        
        {
          text: 'Cod.Referência',
          align: 'start',
          value: 'cd_ref',
        },        
        {
          text: 'Descrição',
          align: 'start',
          value: 'prod',
        },        
        { 
          text: '', 
          value: 'data-table-expand', 
        },
      ],
      opcoesTipo: [
        {
          value: "DS_DESC",
          text: "Descrição do lote",
          type: ["text"],
        },
      ], 
      expanded: [],
      singleExpand: false,
      benched: 0,
    };
  },

  methods: {

    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },

    async mountObj(obj) {
      try {
        this.startLoader();
        let listagem = await this.request({
          metodo: "get",
          sp: "SP_CE360901042",
          params: obj,
        });
        this.getListagem(listagem);
      } catch {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }
    },

    async getListagem(listagemDB) {
      try {
        this.startLoader();
        listagemDB = listagemDB ? listagemDB.database : null;
        if (listagemDB) {
          this.dados = listagemDB.map((p, i) => {
            return {
              ...p,
              itemKey: i,
              dt_incsis: this.maskDataHora(p.dt_incsis),
              dt_oper_acao: this.maskDataHora(p.dt_oper_acao),
            };
          });
        }        
      } catch {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }
    },    

    getColorSituacao (cd_situac) {
      if (cd_situac == 1) return 'green'
      else if (cd_situac == 2) return 'orange'
      else if (cd_situac == 3) return 'red'
      else return 'blue'
    },

    async detalheItem(item) {
      this.dialog = true;
      try {
        this.startLoader();
        let obj = {
          filtros: [{id_guid: item.id_guid}],
          quantidade: "Todos",
        };
        let listagem = await this.chamaMulti("SP_CE360901043", obj);
        this.getListagemD(listagem);
      } catch {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }      
    },

    async getListagemD(listagemDB) {
      try {
        this.startLoader();
        listagemDB = listagemDB ? listagemDB.database : null;
        if (listagemDB) {
          this.dadosD = listagemDB.map((p, i) => {
            return {
              ...p,
              itemKey: i,
              vl_venda: this.formatCurrency(p.venda),
              vl_custo: this.formatCurrency(p.custo),
            };
          });
          console.log('this.dadosD',this.dadosD)
        }        
      } catch {
        this.notification().error({erro: "Erro ao trazer os detalhes"});
      } finally {
        this.stopLoader();
      }
    },     

  },
}